<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('FolderDefaultConnectionCreate')"
    @edit="(id) => onEdit('FolderDefaultConnectionEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'FolderDefaultConnections',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'FolderDefaultConnections',
      tableCaption: 'Подключения по умолчанию сетевой папки',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Подключения по умолчанию сетевой папки',
        },
      ],
      tableHeaders: [
        { text: 'Название', alias: 'name', order: 'name' },
        { text: 'Сервер', alias: 'server', order: 'server' },
        { text: 'Логин', alias: 'login', order: 'login' },
        { text: 'Пароль', alias: 'password', order: 'password' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
